import React from "react";
import { Container, Breadcrumb, Row, Col, Button} from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Section, Post } from "../../components/Core";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Helmet} from "react-helmet";
import {StaticImage} from 'gatsby-plugin-image';
import ReactStars from "react-rating-stars-component";
import DataTable from '../../components/data/banque/avisbanque'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons'

function avis() {

	const banque = 'Orange Bank'
	const go = `/go/?go=${banque}`

	const star1 = {size: 22,value: 4.2,color: "grey",activeColor: "#ffc107",edit: false,};
	const star2 = {size: 22,value: 4.1,color: "grey",activeColor: "#ffc107",edit: false,};
	const star3 = {size: 22,value: 4,color: "grey",activeColor: "#ffc107",edit: false,};
	const star4 = {size: 22,value: 4,color: "grey",activeColor: "#ffc107",edit: false,};
	const star5 = {size: 22,value: 3,color: "grey",activeColor: "#ffc107",edit: false,};
	const star6 = {size: 22,value: 2,color: "grey",activeColor: "#ffc107",edit: false,}; 
	

    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Avis Orange Bank : à lire absolument avant d'ouvrir un compte</title>
                <meta name="description" content="
              Cette banque est-elle réellement gratuite ? Avis détaillé de la banque Orange Bank avec notations, présentation des services et tous les frais bancaires." />
        </Helmet>
      	<Header />
      	<Section>
      	<Container style={{maxWidth: 1280}}>
		  <Breadcrumb id='breadyTop'>
			<Breadcrumb.Item> <Link to={`/`} style={{color: 'black'}}>Accueil</Link></Breadcrumb.Item>
			<Breadcrumb.Item> <Link to={`/banque/`} style={{color: 'black'}}>Avis Banques</Link></Breadcrumb.Item>
			<Breadcrumb.Item active id='bready'>Avis Orange Bank</Breadcrumb.Item>
      	 </Breadcrumb>

	
	<Row>
		<Col sm={9}>  <h1 id='avish1' style={{color: 'rgb(41, 49, 61)'}}>Avis Orange Bank</h1>
          <h2 id='avish2' style={{marginLeft: 0, marginBottom: 25, color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Élu meilleure offre digital de 2019, Orange Bank est une banque en ligne mobile mais qui dispose aussi d’un véritable contact humain grâce à ses 279 boutiques agrées. Découvrez notre avis Orange Bank.</h2>
	
 <a href={go} target='_blank' rel="noreferrer">
                <Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', borderColor: 'rgb(240 0 87)', color: 'white'}}>Voir l'offre <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
                </a>

		  
		  </Col>
	
		<Col sm={3} id='logoAvis'>
		<a href={go} target='_blank' rel="noreferrer">
		<StaticImage
		  	src='../../images/orange-bank-logo.png'
			width={250}
			alt='logo orange bank'
			placeholder='tracedSVG'
			/>
		</a>
		</Col>
	</Row>

		 
<hr></hr>
<Row>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Compte bancaire</p></Col>
			<Col> <ReactStars {...star1} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Carte bancaire</p></Col>
			<Col><ReactStars {...star2} /></Col>
		</Row>
	</Col>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Tarifs</p></Col>
			<Col><ReactStars {...star3} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Application</p></Col>
			<Col><ReactStars {...star4} /></Col>
		</Row>
	</Col>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Comptabilité</p></Col>
			<Col><ReactStars {...star5} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Gestion</p></Col>
			<Col><ReactStars {...star6} /></Col>
		</Row>
	</Col>
</Row>
<hr></hr>
<Post>



<h2>Offre Orange Bank</h2>
<ul>
	<li>Carte Visa gratuite (1)</li>
	<li>80€ bonus de bienvenue</li>
	<li>Sans conditions de revenus</li>
</ul>

<p>(1) Conditions:</p>
<ul>
	<li>après accord Orange Bank</li>
	<li>le client doit personnaliser son code secret sous 30 jours</li>
</ul>



<iframe id='videoYT'src="https://www.youtube.com/embed/JC4msvCl1Vw" title="avis Orange Bank" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>


<hr></hr>

<h2>Un compte bancaire véritablement mobile</h2>
<ul>
	<li>solde mis à jour instantanément après chaque opération</li>
	<li>gestion de toutes vos opérations depuis le mobile (app et text)</li>
	<li>virements</li>
	<li>oppositions</li>
	<li>personnalisation de votre code de carte bancaire</li>
	<li>modification de vos plafonds</li>
	<li>bloquer ou débloquer votre carte à volonté</li>
</ul>

<div style={{maxWidth: 800, margin: 'auto'}}>
			<StaticImage
		  	src='../../images/orange-bank-site.png'
			width={800}
			alt='site orange bank'
			placeholder='tracedSVG'
			id='pickky'
			/>
			</div>


<h2>Comparatif des cartes bancaires de Orange Bank</h2>

<div style={{marginLeft: -5}}>
<DataTable row={[1,2]} what='cartesbancaires' id='postTable'/>
<DataTable row={[1,2]} what='cartesbancaires' id='mobileTable'/>

</div>

<h3>Carte Visa</h3>
<p>
Les services gratuits de la carte Visa sont :
</p>
<ul>
<li>Blocage temporaire/déblocage de la carte en cas de perte</li>
<li>Plafonds de paiements et retraits jusqu’à 100% des revenus déclarés</li>
<li>Retraits en euros gratuits en zone euros</li>
<li>Assistance rapatriement jusqu’à 155 000 €</li>
<li>Assurances voyages (retard, annulation, etc.)</li>
</ul>
<h3>Carte Visa Premium</h3>
<p>Pour la carte Visa premium les services gratuits sont :</p>
<ul>
<li>Blocage temporaire/déblocage de la carte en cas de perte</li>
<li>Plafonds de paiements et retraits jusqu’à 100% des revenus déclarés</li>
<li>Retraits en euros gratuits en zone euros</li>
<li>Renvoi de la carte en 72h, gratuitement</li>
<li>Assistance rapatriement jusqu’à 155 000€</li>
<li>Assurances voyages (retard, annulation, etc.)</li>
<li>Garantie véhicule de location</li>
<li>Garantie neige et montagne</li>
<li>Paiements et retraits gratuits dans le monde</li>
<li>Sécurité renforcée grâce au cryptogramme dynamique</li>
<li>5% remboursés sur les achats chez Orange</li>


</ul>

<h2>Avis Orange Bank sur le paiement mobile</h2>
<p>
Une opérateur téléphone qui ouvre un service bancaire se doit d’être pionnier en terme de paiement mobile. Orange Bank propose un paiement sans contact basé sur la technologie NFC. Ce paiement devient effectif en approchant votre mobile du terminal.
</p>
<h3>
Distinction entre le paiement mobile et le paiement par carte bancaire</h3>
<p>
Le paiement mobile dispose de ses propres plafonds à 1000€/mois. Ce plafond est complètement indépendant de celui de votre carte bancaire. Cette séparation est volontaire et permet à votre paiement mobile de continuer à fonctionner si vous perdez votre carte bancaire et inversement.
</p>
<h3>
La sécurité de vos paiements mobiles</h3>
<ul>
	<li>Sur iOS elle est assuré par l’identification de votre téléphone portable (code pin) ainsi que votre empreinte ou Face ID dans le cas de Apple Pay.</li>
	<li>Sur Android, votre code pin sera demandé à nouveau pour tous les paiements supérieurs à 30€, après 5 paiements sans contact successif ou encore dès que la somme de vos paiements atteint plus de 150€</li>
</ul>

<p>En cas de perte ou vol, vous pouvez faire opposition comme si ce paiement était par carte bancaire.</p>

<h3>Chéquier Orange Bank</h3>
<p>
Orange Bank propose aussi un chéquier sur demande. Malheureusement vos dépôts ne pourront pas se faire dans une boutique Orange mais devront être envoyé à l’adresse suivante : Orange Bank, TSA 10948, 92896 Nanterre Cedex 9
</p><p>Orange Bank donne aussi la possibilité de commander gratuitement un chèque de banque.</p>

			<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
			<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>Orange Bank <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>


<hr></hr>

<h2>Frais Bancaire Avis Orange Bank</h2>
<h3>Virements sortants</h3>
<DataTable row={[1,2]} what='virementSortants' id='postTable'/>
<DataTable row={[1,2]} what='virementSortants' id='mobileTable'/>

<h3>Virements entrants</h3>
<DataTable row={[1,2]} what='virementEntrants' id='postTable'/>
<DataTable row={[1,2]} what='virementEntrants' id='mobileTable'/>

<h3>Découverts</h3>
<DataTable row={[1,2]} what='decouverts' id='postTable'/>
<DataTable row={[1,2]} what='decouverts' id='mobileTable'/>

<h3>Irrégularités et incidents</h3>
<DataTable row={[1,2]} what='irregularites' id='postTable'/>
<DataTable row={[1,2]} what='irregularites' id='mobileTable'/>

<h2>Orange Bank Pour Les Voyageurs</h2>
<h3>Retraits et paiements à l’étranger</h3>

<DataTable row={[1,2]} what='retraits' id='postTable'/>
<DataTable row={[1,2]} what='retraits' id='mobileTable'/>

<h3>Assurances</h3>
<DataTable row={[1,2]} what='assurances' id='postTable'/>
<DataTable row={[1,2]} what='assurances' id='mobileTable'/>

<h2>Service client Orange Bank</h2>
<p>
60% des ouvertures de comptes se fait en agence Orange dans les 279 boutiques avec ses 1900 conseillers agréés IOBSP1. Ces agences sont des points de conseils pour les clients Orange Bank.
</p><p>
Les clients ont aussi la possibilité de contacter centre de relation client en ligne ainsi que le conseiller virtuel Djingo. Djingo a démarré très fort avec déjà plus de 2 millions d’échanges.
</p>
<hr></hr>
<h2>
Avis Orange Bank</h2>
<p>
Orange Bank annonce 500 000 clients avec une progression de 20 000 nouveaux clients tous les mois.</p>
<p>
La banque a été élu “meilleure proposition digitale de 2019” pour la 2ème année consécutive par l’agence D-Rating
</p><p>
L’intégration du paiement mobile semble forte chez les propriétaire de téléphone Apple avec 75% des clients iOS ayant activé Apple Pay.
</p><p>
Avis App Store : 4.4 / 5</p>

<h3>Avantages</h3>
<ul>
	<li>Pas de conditions de revenus</li>
	<li>Pas de domiciliation nécéssaire des salaires</li>
	<li>Pas de versement mensuel obligatoire</li>
	<li>Mise à disposition d’un chéquier</li>
	<li>Chèque de banque</li>
	<li>Découvert authorisé</li>
	<li>Assurance moyen de paiement incluse</li>
</ul>

<h3>Inconvénients</h3>
<ul>
	<li>Pas de carte bancaire haute de gamme (premier, gold ou infinite)</li>
	<li>Pas de livret A</li>
	<li>Pas de LDD</li>
	<li>Transactions minimum par mois pour avoir accès au gratuit</li>
	<li>Uniquement pour les particuliers (pas de compte auto entrepreneur)</li>
</ul>



<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>Je veux en savoir + <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>

	<h2>Conditions d’ouverture d’un compte Orange Bank</h2>
<p>Si l’ouverture d’un compte est sans condition de revenus, il ne faudra pas oublié de faire votre quota de transactions tous les mois pour garder sa gratuité.
</p><p>
Le versement obligatoire à l’ouverture est assez bas et il n’y à aucune obligation de domicilier votre salaire
</p>
<h3>
Ouvrir un compte Orange Bank</h3>
<p>
L’ouverture d’un compte Orange Bank se fait en 6 étapes directement de votre mobile. Mais attention, il y’a une étape zéro qui consiste à vérifier que votre téléphone portable est bien compatibilite.
</p>
<p>
Étape 0 – Assurez-vous d’avoir un smartphone avec Android 5.0 ou + ou iOS 9 et +
</p><p>
Étape 1 – Remplir un formulaire d’inscription
</p><p>
Étape 2 – Choisir l’offre dont vous avez besoin
</p><p>
Étape 3 – Vérifier vos informations (c’est une étape mais une étape rapide ;-))
</p><p>
Étape 4 – Signer votre contrat et vérifiant un code reçu par SMS
</p><p>
Étape 5 – Documents à fournir :
</p>
<ul>
	<li>2 pièces d’identités</li>
	<li>un RIB</li>
	<li>justificatif de domicile</li>
</ul>

<p>Étape 6 – Faire un premier virement entrant d’un compte bancaire extérieur</p>



<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>Ouvrir un compte <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>

		</Post>
		</Container>
		</Section>
		<Footer/>
      </PageWrapper>
    </>
    )
}

export default avis
